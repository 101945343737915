<template>
  <div data-cy="asset-accounts-page">
    <div class="row">
      <div class="col-xl-2 col-lg-4 col-md-5 col-12">
        <FormGroup id="search-accounts" v-model="search" type="text" is-alt placeholder="Search..." />

        <div class="form-group">
          <div class="custom-control custom-switch mb-1 ml-2">
            <input id="show-sub-meters" v-model="hideSubMeters" type="checkbox" class="custom-control-input" name="show-sub-meters" />
            <label class="custom-control-label" for="show-sub-meters"><small>Hide Sub Meters</small></label>
          </div>
        </div>

        <VerticalPills v-if="accountTypeTabs.length > 0 && !loading" item-key="_id" :groups="accountTypeTabs" :items="groupedAccountsFiltered" alt>
          <template #title="{ title }"><UtilityTypeIcon :type="title" /> </template>
          <template #item="{ item, group }">
            <router-link
              v-if="selectedAccount"
              class="nav-link"
              :class="{ active: selectedAccount._id === item.id }"
              :to="{
                name: item.parentAccountId ? 'asset-accounts-overview' : $route.name,
                params: { id: $route.params.id, accountId: item._id, subMeter: item.parentAccountId }
              }"
              @click.native="selectedParentId = item.parentAccountId || item._id"
            >
              <div class="mb-0">
                <UtilityTypeIcon v-if="item.type !== group.title" :text="false" :type="item.type" class="mr-0" no-fw />{{ item.name }}
                <span v-if="item.status === 'inactive'" class="badge badge-danger">Inactive</span>
              </div>
              <div v-if="item.meterPointNumber || item.meterSerialNumber || item.deviceId" class="text-muted small">
                {{ item.meterPointNumber || item.meterSerialNumber || item.deviceId }}
              </div>
            </router-link>
          </template>
        </VerticalPills>

        <router-link
          v-if="$permissions.write('asset', asset)"
          data-cy="new-account-btn"
          active-class="active"
          :to="{ name: 'account-wizard', query: { assetId: asset._id } }"
          class="btn btn-link font-w600 btn-block"
          ><i class="fa fa-plus"></i> NEW</router-link
        >
      </div>

      <div class="col">
        <div v-if="loading" class="d-flex justify-content-center flex-grow-1">
          <SpinnerLogo>Loading account data...</SpinnerLogo>
        </div>

        <div v-else-if="selectedAccount && selectedAccount._id">
          <AccountInfo :key="$route.name" :account="selectedAccount" />

          <InnerTabsHeader class="mb-4" :tabs="tabs" :link-params="{ id: $route.params.id, accountId: $route.params.accountId }" />
          <div>
            <div v-if="selectedAccount.deleted" class="alert alert-danger d-flex justify-content-between align-items-center">
              <span>
                <strong><i class="fa fa-ban fa-fw"></i> DELETED</strong> This account has been deleted. You can restore by using the button on the
                right.
              </span>
              <span
                ><a class="btn btn-primary btn-sm mb" href="#" @click.prevent="onClickRestore(selectedAccount._id)"
                  ><i class="fa fa-fw fa-arrow-rotate-left"></i> Restore</a
                ></span
              >
            </div>
            <div v-else-if="selectedAccount.status !== 'active'" class="alert alert-warning d-flex justify-content-between align-items-center">
              <span>
                <strong><i class="fa fa-triangle-exclamation fa-fw"></i> INACTIVE</strong> This account is currently inactive.
              </span>
            </div>
            <div
              v-if="selectedAccount.parentAccount && selectedAccount.parentAccount.deleted"
              class="alert alert-danger d-flex justify-content-between align-items-center"
            >
              <span>
                <strong><i class="fa fa-ban fa-fw"></i> PARENT DELETED</strong> This meter's parent account has been deleted. You can restore by using
                the button on the right.
              </span>
              <span
                ><a class="btn btn-primary btn-sm mb" href="#" @click.prevent="onClickRestore(selectedAccount.parentAccount._id)"
                  ><i class="fa fa-fw fa-arrow-rotate-left"></i> Restore</a
                ></span
              >
            </div>
            <div
              v-else-if="selectedAccount.parentAccount && selectedAccount.parentAccount.status !== 'active'"
              class="alert alert-warning d-flex justify-content-between align-items-center"
            >
              <span>
                <strong><i class="fa fa-triangle-exclamation fa-fw"></i> PARENT INACTIVE</strong> This meter's parent account is currently inactive.
              </span>
            </div>

            <router-view :key="selectedAccount._id"></router-view>
          </div>
        </div>

        <div v-else-if="accounts.length === 0" class="alert alert-warning">
          This asset has no accounts. Click "NEW" on the left sidebar to create a new account.
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import AccountInfo from '@/components/accounts/AccountInfo';
import FormGroup from '@/components/FormGroup';
import InnerTabsHeader from '@/components/base/InnerTabsHeader';
import SpinnerLogo from '@/components/SpinnerLogo';
import UtilityTypeIcon from '../../../components/UtilityTypeIcon.vue';
import VerticalPills from '@/components/base/VerticalPills';

export default {
  name: 'AssetAccount',
  components: {
    AccountInfo,
    FormGroup,
    InnerTabsHeader,
    SpinnerLogo,
    VerticalPills,
    UtilityTypeIcon
  },
  data() {
    return {
      loading: true,
      search: '',
      accountTypeFilter: null,
      selectedParentId: '',
      hideSubMeters: false
    };
  },
  computed: {
    ...mapGetters({
      asset: 'asset/asset',
      accounts: 'asset/accounts',
      invoiceKeys: 'invoiceTemplate/invoiceKeys',
      suppliers: 'supplier/suppliers',
      selectedAccount: 'asset/selectedAccount'
    }),
    tabs() {
      const isSubMeter = this.selectedAccount.parentAccountId;

      const tabs = [
        { label: 'Overview', route: 'asset-accounts-overview', exact: true },
        { label: 'Readings', route: 'asset-accounts-readings' },
        ...(!isSubMeter ? [{ label: 'Invoices', route: 'asset-accounts-invoices' }] : []),
        { label: 'Non-Cumulative Data', route: 'asset-accounts-consumptions' },
        ...(!this.$auth.settings.hideCostData && !isSubMeter ? [{ label: 'Contracts', route: 'asset-accounts-contracts' }] : []),
        ...(!isSubMeter ? [{ label: 'Data Quality', route: 'asset-accounts-data-quality' }] : []),
        ...(!isSubMeter ? [{ label: 'Misc', route: 'asset-accounts-credit-notes' }] : [])
      ];

      if (this.$permissions.write('account', this.selectedAccount)) {
        tabs.push({ label: 'Edit', route: 'asset-accounts-edit', accountId: this.selectedAccount._id });
      }

      return tabs;
    },
    accountsFiltered() {
      const filteredAccounts = this.accounts.filter(a =>
        this.search
          ? (a.meterPointNumber || '').toLowerCase().includes(this.search.toLowerCase()) ||
            (a.meterPointNumber2 || '').toLowerCase().includes(this.search.toLowerCase()) ||
            (a.meterSerialNumber || '').toLowerCase().includes(this.search.toLowerCase()) ||
            (a.siteCode || '').toLowerCase().includes(this.search.toLowerCase()) ||
            (a.name || '').toLowerCase().includes(this.search.toLowerCase())
          : true
      );

      // Show sub meters parent accounts in search
      filteredAccounts
        .filter(f => f.parentAccountId)
        .forEach(a => {
          const isParentAccountIncluded = filteredAccounts.find(acc => acc._id === a.parentAccountId);
          if (!isParentAccountIncluded) {
            const parentAccount = this.accounts.find(acc => acc._id === a.parentAccountId);
            if (parentAccount) filteredAccounts.push(parentAccount);
          }
        });

      return filteredAccounts;
    },
    accountTypes() {
      return ['electricity', 'gas', 'water', 'waste', 'solar', 'heating', 'cooling', 'flow', 'temperature', 'other'].filter(type =>
        this.accounts.some(a => !a.deleted && type === a.type)
      );
    },
    accountTypeTabs() {
      const groups =
        this.groupedAccountsFiltered.archived && this.groupedAccountsFiltered.archived.length > 0
          ? [...this.accountTypes, 'archived']
          : this.accountTypes;

      return groups.map(type => ({
        title: type,
        show: type !== 'archived'
      }));
    },
    groupedAccounts() {
      const elements = this.accountTypes.reduce((acc, type) => {
        const accounts = this.accounts.filter(a => !a.deleted && a.type === type);

        acc[type] = accounts.filter(a => !a.parentAccountId);
        acc[type].sort((a, b) => a.status.localeCompare(b.status));

        return acc;
      }, {});

      elements.archived = this.accounts.filter(a => a.deleted);
      elements.archived.sort((a, b) => a.status.localeCompare(b.name));

      return elements;
    },
    groupedAccountsFiltered() {
      const primaryMeters = this.accountsFiltered.filter(a => !a.deleted && !a.parentAccountId).map(a => ({ ...a, isSubItem: false }));
      const subMeters = this.accountsFiltered.filter(a => !a.deleted && a.parentAccountId).map(a => ({ ...a, isSubItem: true }));

      const orphanSubMeters = subMeters
        .filter(a => !this.hideSubMeters && !primaryMeters.find(p => p._id === a.parentAccountId))
        .map(a => ({ ...a, isSubItem: false }));

      const elements = this.accountTypes.reduce((acc, type) => {
        // Handle case where user only has access to a sub-meter
        acc[type] = [...primaryMeters, ...orphanSubMeters].filter(a => a.type === type);
        acc[type].sort((a, b) => a.status.localeCompare(b.status));

        if (this.hideSubMeters) return acc;

        // Handle submeters
        subMeters.sort((a, b) => b.name.localeCompare(a.name));

        subMeters.forEach(account => {
          const parentAccountIndex = acc[type].findIndex(acc => acc._id === account.parentAccountId);
          if (parentAccountIndex > -1) acc[type].splice(parentAccountIndex + 1, 0, account);
        });
        return acc;
      }, {});

      elements.archived = this.accountsFiltered.filter(a => a.deleted && !a.parentAccountId);
      elements.archived.sort((a, b) => a.status.localeCompare(b.name));

      this.accountsFiltered
        .filter(a => a.parentAccountId && a.deleted)
        .map(a => ({ ...a, isSubItem: true }))
        .forEach(account => {
          const parentAccountIndex = elements.archived.findIndex(acc => acc._id === account.parentAccountId);
          elements.archived.splice(parentAccountIndex + 1, 0, account);
        });

      return elements;
    },
    defaultAccount() {
      if (this.accounts.length === 0) return null;

      const defaultType = Object.keys(this.groupedAccounts)[0];

      const accountsInType = this.groupedAccounts[defaultType];

      return accountsInType.length > 0 ? accountsInType[0] : this.accounts[0];
    }
  },

  async mounted() {
    this.listUnits();

    // Get invoice keys
    await this.getInvoiceKeys();

    // Get suppliers
    await this.listSuppliers();

    // Get distributors
    await this.listDistributors({ data: { params: { countryCode: this.asset.address.countryCode } } });

    // Get asset accounts (incl. deleted)
    await this.getAccounts({
      id: this.$route.params.id,
      data: {
        params: {
          $sort: 'name:1',
          $deleted: true,
          $populate: 'childAccounts,parentAccount',
          $select: 'parentAccount.status,parentAccount.deleted'
        }
      }
    });

    this.selectAccount(this.$route.params.accountId);

    this.loading = false;
  },
  methods: {
    ...mapActions({
      getAccounts: 'asset/accounts',
      updateAccount: 'account/update',
      restoreAccount: 'account/restore',
      listDistributors: 'supplier/listDistributors',
      getInvoicesByAccount: 'asset/getInvoicesByAccount',
      getInvoiceKeys: 'invoiceTemplate/getInvoiceKeys',
      listSuppliers: 'supplier/list',
      listUnits: 'util/listUnits'
    }),
    ...mapMutations({
      setSelectedAccount: 'asset/SET_SELECTED_ACCOUNT',
      resetState: 'asset/RESET_STATE',
      setValidationErrors: 'invoice/SET_VALIDATION_ERRORS'
    }),
    onClickChangeType(name) {
      this.accountTypeFilter = name;
      this.search = '';
      this.$router.push({ name: 'asset-accounts-overview', params: { accountId: this.groupedAccountsFiltered[name][0]._id } });
    },
    async onClickRestore(accountId) {
      const restoredAccount = await this.restoreAccount({ id: accountId });

      if (restoredAccount._id) {
        this.$toasted.success(`Account has been restored.`);
      } else {
        this.$toasted.error(`Account could not be restored.`);
      }

      await this.getAccounts({ id: this.$route.params.id, data: { params: { $sort: 'name:1', $deleted: true } } });
    },
    selectAccount(accountId) {
      if (this.accounts.length === 0) return;

      let selectedAccount = { ...this.defaultAccount };

      // accountId is not defined when loading the page from another asset tab
      if (accountId) {
        const accountFromRoute = this.accounts.find(a => a._id === accountId);

        if (accountFromRoute) selectedAccount = accountFromRoute;
      } else {
        this.$router.push({ name: 'asset-accounts-overview', params: { accountId: selectedAccount._id } });
      }

      // Set the selected account, and get its invoices
      this.setSelectedAccount(selectedAccount);
      if (!selectedAccount.parentAccountId) this.getInvoicesByAccount({ id: selectedAccount._id, data: { params: { $deleted: true } } });
      this.accountTypeFilter = selectedAccount.deleted ? 'archived' : selectedAccount.type;

      this.selectedParentId = selectedAccount.parentAccountId || selectedAccount.id;
    }
  },
  async beforeRouteUpdate(to, from, next) {
    this.setValidationErrors({});

    const isNewAsset = to.params.id !== from.params.id;
    const isNewAccount = to.params.accountId !== from.params.accountId;

    if (isNewAsset) {
      await this.getAccounts({ id: to.params.id, data: { params: { $sort: 'name:1', $deleted: true } } });
    }

    if (isNewAccount) {
      this.selectAccount(to.params.accountId);
    }

    next();
  }
};
</script>
